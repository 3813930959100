import {
    SmartToyOutlined,
    RecyclingOutlined,
    HearingOutlined,
    KeyboardOutlined,
    DrawOutlined,
    SportsEsportsOutlined,
  } from "@mui/icons-material";
  
  const iconMapping = {
    SmartToyOutlined,
    RecyclingOutlined,
    HearingOutlined,
    KeyboardOutlined,
    DrawOutlined,
    SportsEsportsOutlined,
  };
  
  export default iconMapping;